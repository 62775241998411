<!-- 变价调拨 -->
<template>
  <div class="page">
    <div class="pageTop">
      <div class="top">
        <Form inline class="formMarginBtm20">
          <FormItem>
            <span>发起仓库：</span>
            <Select placeholder="请选择" class="iviewIptWidth250 marginRight40" v-model="warehouse_id" filterable clearable @on-select="warehouseChange" disabled>
              <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>调拨仓库：</span>
            <Select placeholder="请选择" clearable filterable class="iviewIptWidth250 marginRight40" v-model="postForm.to_warehouse_id" disabled>
              <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id" :disabled="item.warehouse_id == warehouse_id">{{ item.warehouse_name }}</Option>
            </Select>
          </FormItem>
          <!-- 经手人 -->
          <FormItem>
            <span>经手人：</span>
            <Select placeholder="请选择" clearable filterable class="iviewIptWidth250 marginRight40" v-model="postForm.processor_id" @on-change="processorChange" disabled>
              <Option v-for="(item, index) in dealList" :value="item.id" :key="index">{{ item.user_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>审核人：</span>
            <Select placeholder="请选择" clearable filterable class="iviewIptWidth250" v-model="postForm.reviewer_id" disabled>
              <Option v-for="(item, index) in reviewList" :value="item.id" :key="index" :disabled="item.id == postForm.processor_id">{{ item.user_name }}</Option>
            </Select>
          </FormItem>
        </Form>
      </div>
      <div class="btm">
        <div class="btmRight">
          <p>{{ postForm.transfer_type == '1' ? '同价调拨' : '变价调拨' }}<span class="fr pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span></p>
          <TableCom :option_page="option_page" :productList="postForm.transfer_type == '1' ? listColumns1 : listColumns2" :productData="listData" border :style="{ 'min-height': listData.length == 0 ? '0px' : '400px' }" :loading="loading" :isLoad="loading">
            <template slot-scope="{ row, index }" slot="bar_code">
              <i-input placeholder="请输入" :value="row.bar_code" @on-change="changeIpt($event, 'bar_code', index)"></i-input>
            </template>
            <template slot-scope="{ row, index }" slot="batch_number">
              <i-input placeholder="请输入" :value="row.batch_number" @on-change="changeIpt($event, 'batch_number', index)"></i-input>
            </template>
            <template slot-scope="{ row, index }" slot="quantity">
              <i-input placeholder="请输入" :value="row.quantity" @on-change="changeIpt1($event, 'quantity', index)"></i-input>
            </template>
            <template slot-scope="{ row, index }" slot="current_unit_price" v-if="postForm.transfer_type == '2'">
              <i-input placeholder="请输入" :value="row.current_unit_price" @on-change="changeIpt1($event, 'current_unit_price', index)"></i-input>
            </template>
          </TableCom>
          <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData.length == 0 && !loading">
            <img style="margin-top: 100px;" src="../../../assets/images/tableNoData.png" />
          </div>
          <div class="summary">
            <div>
              <span style="margin-right: 20px;"
                >总数量：<span class="color389">{{ totalNum }}</span></span
              >
              <span>合计金额：</span>
              <span class="color389">¥{{ totalMoney }}</span>
            </div>
          </div>
          <div class="tipsDiv">
            <div class="tipsFl">备注</div>
            <div class="tipsFr">
              <i-input type="textarea" maxlength="100" show-word-limit placeholder="请输入" v-model.trim="postForm.note" disabled></i-input>
            </div>
          </div>
          <div class="button">
            <span class="pageBtn finger btnReset fr" @click="back">返回</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 查询库存 -->
    <Modal v-model="inventory" :mask-closable="false" width="1260" class="modal">
      <p slot="header" class="header">库存查询</p>
      <Table class="table" :columns="inventoryColumns" :data="inventoryData" @on-selection-change="selectChange" border></Table>
      <div slot="footer" class="clearfix">
        <span class="longTextBtn finger btnSure fr marginLeft20" @click="sureChoose">添加至清单</span>
        <span class="pageBtn finger btnCancle fr" @click="inventory = false">取消</span>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import TableSetup from '@/components/tableSetup'
import TableCom from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'allocateDetailConsign',
  components: {
    TipsComponent,
    TableSetup,
    TableCom,
  },
  computed: {
    totalNum() {
      let totle = 0
      this.listData.forEach((e, i) => {
        totle = totle * 1 + e.quantity * 1
        // totle++
      })
      return totle
    },
    totalMoney() {
      let totle = 0
      if (this.postForm.transfer_type == '1') {
        this.listData.forEach((e, i) => {
          // totle = totle * 1 + e.unit_price * e.quantity
          totle = totle * 1 + (e.unit_price && e.quantity ? e.unit_price.replace(/\,/g, '') * e.quantity : 0)
        })
        return totle.toFixed(2)
      } else {
        this.listData.forEach((e, i) => {
          // totle = totle * 1 + e.current_unit_price * e.quantity
          totle = totle * 1 + (e.unit_price && e.quantity ? e.unit_price.replace(/\,/g, '') * e.quantity : 0)
        })
        return totle
      }
    },
  },
  data() {
    return {
      // 动态表头
      id: '',
      titleList: [],
      option_page: '33',
      setupStatus: false,

      deleteStatus: false,
      postStatus: false,
      warehouse_id: '-1',
      queryFrom: {
        warehouse_id: '',
        product_id: '',
        specification_id_str: [],
        warehouse_name: '',
      },
      storeList: [],
      inventory: false,
      inventoryColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
        },
        {
          title: '序列号',
          key: 'serial_number',
          align: 'center',
        },
        {
          title: '供货单价',
          key: 'unit_price',
          align: 'center',
        },
        {
          title: '数量',
          key: 'inventory_quantity',
          align: 'center',
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
        },
        {
          title: '包装规格',
          key: 'packing_size ',
          align: 'center',
        },
      ],
      inventoryData: [],
      total: 1,
      listData: [],
      listColumns1: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
        },
        {
          title: '条形码',
          align: 'center',
          key: 'bar_code',
        },
        {
          title: '批号',
          align: 'center',
          key: 'batch_number',
        },
        {
          title: '数量',
          align: 'center',
          // key: 'quantity',
          key: 'inventory_quantity',
          width: 100,
        },
        {
          title: '销售单价',
          align: 'center',
          key: 'unit_price',
        },
      ],
      listColumns2: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minQidth: 180,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
        },
        {
          title: '条形码',
          align: 'center',
          key: 'bar_code',
        },
        {
          title: '批号',
          align: 'center',
          key: 'batch_number',
        },
        {
          title: '数量',
          align: 'center',
          // key: 'quantity',
          key: 'inventory_quantity',
          width: 100,
        },
        {
          title: '原销售单价',
          align: 'center',
          width: 120,
          render: (h, param) => {
            return h('div', [h('span', param.row.unit_price ? '¥' + param.row.unit_price : 0)])
          },
        },
        {
          title: '销售单价',
          align: 'center',
          width: 100,
          render: (h, param) => {
            return h('div', [h('span', param.row.current_unit_price ? '¥' + param.row.current_unit_price : '0')])
          },
        },
      ],
      // 条形码
      // 表单拉下选项
      // 查询参数
      postForm: {
        from_warehouse_id: '',
        from_warehouse_name: '',
        to_warehouse_name: '',
        to_warehouse_id: '',
        transfer_type: '', // 1是同价  2是变价,
        processor_id: '',
        processor: '',
        reviewer_id: '',
        note: '',
      },
      dealList: [], // 经手
      reviewList: [], // 审核
      productList: [],
      modelList: [], // sku
      hadSelectList: [],
      deleteIndex: '',
      loading: true
    }
  },
  mounted() {
    this.postForm.transfer_type = this.$route.query.addType
    this.id = this.$route.query.id
    this.queryOption()
    this.specialUser('1')
    this.specialUser('2')
    this.queryStoreList()
    this.queryDetail()
  },
  methods: {
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http.get(this.$apiConsign.systemOptionItem, { option_page: this.option_page }).then(res => {
        localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
        if (this.postForm.transfer_type == '1') {
          this.titleList = res.data.enable
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns1 = []
          this.listColumns1.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns1 = [...this.listColumns1, ...this.titleList]
          this.listColumns1 = JSON.parse(JSON.stringify(this.listColumns1))
        } else if (this.postForm.transfer_type == '2') {
          this.titleList = res.data.enable
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns2 = []
          this.listColumns2.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns2 = [...this.listColumns2, ...this.titleList]
          this.listColumns2 = JSON.parse(JSON.stringify(this.listColumns2))
        }
      })
    },
    queryDetail() {
      this.loading = true
      this.$http.get(this.$apiConsign.warehouseTransferDetail, { id: this.id, dynamic_header: 1 }, true).then(res => {
        this.postForm = res.data
        this.listData = res.data.line_info
        this.loading = false
        this.listData.forEach((e, i) => {
          this.listData[i].licence_valid_period = e.licence_valid_period ? this.$moment(e.licence_valid_period * 1000).format('YYYY-MM-DD') : null
          this.listData[i].business_license_valid_period = e.business_license_valid_period ? this.$moment(e.business_license_valid_period * 1000).format('YYYY-MM-DD') : null
          this.listData[i].create_time = e.create_time ? this.$moment(e.create_time * 1000).format('YYYY-MM-DD') : null
          this.listData[i].end_time = e.end_time ? this.$moment(e.end_time * 1000).format('YYYY-MM-DD') : null
          this.listData[i].production_date = e.production_date ? this.$moment(e.production_date * 1000).format('YYYY-MM-DD') : null
          this.listData[i].production_record_date = e.production_record_date ? this.$moment(e.production_record_date * 1000).format('YYYY-MM-DD') : null
          this.listData[i].record_valid_period = e.record_valid_period ? this.$moment(e.record_valid_period * 1000).format('YYYY-MM-DD') : null
          this.listData[i].valid_period = e.valid_period ? this.$moment(e.valid_period * 1000).format('YYYY-MM-DD') : null
          this.listData[i].production_license_valid_period = e.production_license_valid_period ? this.$moment(e.production_license_valid_period * 1000).format('YYYY-MM-DD') : null
        })
        this.warehouse_id = res.data.from_warehouse_id
      })
    },
    // 确认删除
    sureDelete() {
      this.listData.splice(this.deleteIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
    },
    // 删除
    deleteRow(index) {
      this.deleteIndex = index
      this.deleteStatus = true
    },
    // 确认选择 关闭弹窗
    sureChoose() {
      if (this.hadSelectList.length == 0) {
        this.$Message.warning('请至少选择一个产品')
        return
      }
      this.inventory = false
      if (this.listData.length > 0) {
        let select = this.meterList.map(item => item.product_id + item.specification_id)
        this.hadSelectList = this.hadSelectList.filter(i => {
          return !select.includes(i.product_id + i.specification_id)
        })
        let arr = [...this.listData, ...this.hadSelectList]
        this.listData = JSON.parse(JSON.stringify(arr))
      } else {
        this.listData = JSON.parse(JSON.stringify(this.hadSelectList))
      }
    },
    // 选择数据发生改变时
    selectChange(list) {
      this.hadSelectList = list
    },
    // 点击确认
    post() {
      if (this.warehouse_id <= 0) {
        this.$Message.warning('请选择发起仓库')
        return null
      }
      if (!this.postForm.to_warehouse_id) {
        this.$Message.warning('请选择调拨仓库')
        return null
      }
      if (!this.postForm.processor_id) {
        this.$Message.warning('请选择经手人')
        return null
      }
      if (!this.postForm.reviewer_id) {
        this.$Message.warning('请选择审核人')
        return null
      }
      if (this.listData.length == 0) {
        this.$Message.warning('请至少添加一组产品调拨')
        return null
      }
      this.postForm.line_info = this.listData
      this.postForm.from_warehouse_id = this.warehouse_id
      this.postStatus = true
    },
    // 确认提交
    surePost() {
      this.$http.post(this.$apiConsign.warehouseTransfer, this.postForm, false).then(res => {
        this.$Message.success('提交成功')
        this.listData = []
        this.postForm = {}
        this.queryFrom = {}
        this.warehouse_id = '-1'
        this.postStatus = false
      })
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.product_code_array = []
      if (!e) return
      this.modelList = []
      this.queryProduct(this.warehouse_id, e)
    },
    // 弹窗列表
    query() {
      if (this.warehouse_id <= 0) {
        this.$Message.warning('请选择发起仓库')
        return null
      }
      if (!this.queryFrom.product_id) {
        this.$Message.warning('请选择产品名称')
        return null
      }
      this.$http.get(this.$apiConsign.inventoryProductLookup, { product_id: this.queryFrom.product_id, warehouse_id: this.warehouse_id, specification_id_str: this.queryFrom.specification_id_str.join(',') }, false).then(res => {
        if (res.data.length > 0) {
          this.inventoryData = res.data
          for (let i = 0; i < this.inventoryData.length; i++) {
            this.inventoryData[i].quantity = this.inventoryData[i].inventory_quantity
            this.inventoryData[i].original_unit_price = this.inventoryData[i].unit_price
          }
          this.inventory = true
        } else {
          this.$Message.warning('暂无数据')
          return null
        }
      })
    },
    // 查询产品列表和sku列表
    queryProduct(id = null, subId = null) {
      this.$http.get(this.$apiConsign.inventoryProductLookup, { product_id: subId, warehouse_id: this.warehouse_id }, false).then(res => {
        if (!subId) {
          this.productList = res.data
        } else {
          this.modelList = res.data
        }
      })
    },
    // 经手人改变
    processorChange(e) {
      if (e >= 0) {
        for (let i = 0; i < this.dealList.length; i++) {
          if (e == this.dealList[i].id) {
            this.postForm.processor = this.dealList[i].user_name
          }
        }
      } else {
        this.postForm.reviewer_id = ''
      }
    },
    // 仓库改变
    warehouseChange(e) {
      if (e.value >= 0) {
        this.postForm.to_warehouse_id = ''
        this.queryFrom.product_id = ''
        this.queryFrom.specification_id_str = []
        this.warehouse_id = e.value
        for (let i = 0; i < this.storeList.length; i++) {
          if (this.storeList[i].warehouse_id == e.value) {
            this.postForm.from_warehouse_id = e.value
            this.postForm.from_warehouse_name = this.storeList[i].warehouse_name
          }
        }
        this.queryProduct(e.value, null)
      } else {
        this.postForm.to_warehouse_id = ''
        this.queryFrom.product_id = ''
        this.queryFrom.specification_id_str = []
      }
    },
    // 审核人
    specialUser(num) {
      this.$http.get(this.$apiConsign.publicSpecialUser, { user_type: num }, true).then(res => {
        if (num == '1') {
          this.dealList = res.data
        } else {
          this.reviewList = res.data
        }
      })
    },
    // 返回
    back() {
      this.$router.go(-1)
    },
    upload() {
      this.$Message.warning('功能开发中...')
    },
    // 查询仓库列表
    queryStoreList() {
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
        is_consign:1
      }
      this.$http.get(this.$apiConsign.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 输入框改变
    changeIpt(e, name, index) {
      this.$set(this.listData[index], name, e.target.value.trim())
    },
    changeIpt1(e, name, index) {
      if (e.target.value * 1 < 0) {
        this.$set(this.listData[index], name, 1)
      } else {
        this.$set(this.listData[index], name, e.target.value.trim() * 1)
      }
    },
  },
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  flex-direction: column;

  .pageTop {
    overflow-y: auto;
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 22px 0;

    .item {
      width: 325px;
      text-align: right;

      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
    }
  }

  .btm {
    flex: 1;
    display: flex;

    .btmRight {
      flex: 1;
      padding: 23px;
      border-top: 1px solid #e8eaec;
      display: flex;
      flex-direction: column;
      overflow: auto;
      p {
        margin-bottom: 23px;
        font-size: 18px;
        color: #525b6d;
      }

      .summary > span:first-child {
        margin-right: 30px;
      }

      .tipsDiv {
        margin-top: 20px;
        border: 1px solid #e8eaec;
        height: 230px;
        display: flex;
        .tipsFl {
          width: 210px;
          line-height: 230px;
          padding-left: 33px;
          border-right: 1px solid #e8eaec;
          background: #f8f8f9;
          color: #525b6d;
          font-size: 14px;
        }
        /deep/ .tipsFr {
          flex: 1;
          display: flex;
          padding: 8px 15px 8px 23px;
          .ivu-input-wrapper {
            height: 100% !important;
          }
          .ivu-input {
            height: 100% !important;
          }
        }
      }

      .button {
        height: 36px;
        line-height: 36px;
        text-align: right;
        margin-top: 20px;
      }
    }
  }
}
.marginRight40 {
  margin-right: 40px;
}
.marginLeft16 {
  margin-left: 16px;
}
</style>
